/** @jsx jsx */
import { jsx } from "theme-ui"
import Logo from "./logo"
import Navigation from "./navigation"
import "../assets/scss/header.scss"

const headerStyles = {
  siteHeader: {
    py: [3, 3, 3, "24px"],
  },
  container: {
    variant: "variants.container",
    px: [3, 4, 4, 5],
    py: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  cartButton: {
    listStyle: "none",
    color: "#b0bd96",
    px: 3,
    py: 2,
    display: ["none", "none", "block"],
    backgroundColor: "#000",
    border: "2px solid #5b624d",
    borderRadius: "6px",
    margin: ["10px", null],
    mr: "0",
    textAlign: "center",
    transition: "all .3s ease-in-out",
    "&:hover": {
      border: "2px solid rgba(255,255,255,0.5)",
      color: "#fff",
    },
    ".icon.-left": {
      position: "relative",
      top: "-2px",
      mr: "0",
    },
  },
}

const Header = props => (
  <header className="site-header" sx={headerStyles.siteHeader}>
    <div sx={headerStyles.container}>
      <Logo title={props.title} url="/" />
      <Navigation />
    </div>
  </header>
)

export default Header
