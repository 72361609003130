/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import { RiMenu3Line, RiCloseLine } from "react-icons/ri"

import "../assets/scss/navigation.scss"

const MenuItems = [
  {
    path: "/jamstack/",
    title: "Jamstack Templates",
  },
  {
    path: "/events/",
    title: "Events",
  },
  {
    path: "/blog/",
    title: "Blog",
  },
  {
    path: "/newsletter/",
    title: "Newsletter",
  },
]

const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMenu: false }

    this.handleToggleClick = this.handleToggleClick.bind(this)
  }

  handleToggleClick() {
    this.setState(state => ({
      showMenu: !state.showMenu,
    }))
  }

  render() {
    const listMenuItems = MenuItems.map(menuItem => (
      <ListLink key={menuItem.title.toString()} to={menuItem.path}>
        {menuItem.title}
      </ListLink>
    ))

    const firstMenuItem = (
      <Link to={listMenuItems[0].props.to}>
        {listMenuItems[0].props.children}
      </Link>
    )

    listMenuItems.shift()

    return (
      <nav className="site-navigation">
        <button
          onClick={this.handleToggleClick}
          className={"menu-trigger" + (this.state.showMenu ? " is-active" : "")}
        >
          <div className="icon-menu-line">
            <RiMenu3Line />
          </div>
          <div className="icon-menu-close">
            <RiCloseLine />
          </div>
        </button>
        <ul>
          <li>{firstMenuItem}</li>
          {listMenuItems}
        </ul>
      </nav>
    )
  }
}

export default Navigation
