/** @jsx jsx */
import { jsx } from "theme-ui"
import ConvertKitForm from "convertkit-react"
import convertkitStyles from "../components/convertkit.styles"

const config = {
  formId: 1461381,
}

export default props => {
  const data = {
    title: props.title || "Join the Newsletter",
    tagline:
      props.tagline ||
      "Subscribe to get latest updates on Jamstack, How-to guides and best Jamstack templates in your inbox",
    helpText:
      props.helpText ||
      "We respect your privacy. you can Unsubscribe at any time.",
    newsletter: props.newsletter || false,
  }
  return (
    <div sx={{ ...convertkitStyles }}>
      {data.newsletter === true ? (
        <h1 sx={convertkitStyles.title}>{data.title}</h1>
      ) : (
        <h3 sx={convertkitStyles.title}>{data.title}</h3>
      )}
      <p sx={convertkitStyles.description}>{data.tagline}</p>
      <ConvertKitForm {...config} />
      <p sx={convertkitStyles.Unsubscribe}>{data.helpText}</p>
    </div>
  )
}
