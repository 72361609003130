const convertkitStyles = {
  variant: "cards.primary",
  px: [3, 5],
  py: [3, 4],
  mb: 4,
  boxShadow: "none",
  border: "3px solid rgb(183, 185, 162)",
  background:
    "rgba(0, 0, 0, 0) linear-gradient(-45deg, rgb(230, 229, 211) 34.96%, rgb(199, 200, 168) 102.28%) repeat scroll 0% 0%",
  "&:hover": {
    boxShadow: "none",
  },
  input: {
    py: 3,
    mt: [3, 4],
    mr: 3,
    px: 3,
    mb: 0,
    width: ["100%", "inherit"],
    appearance: "none",
    border: "1px solid transparent",
    borderRadius: "6px",
    backgroundColor: "rgba(255,255,255,0.8)",
    outline: "none"
  },
  button: {
    backgroundColor: "#222",
    color: "#fff",
    appearance: "none",
    py: 3,
    px: 4,
    mt: [3, 3, 4],
    mb: 3,
    border: "1px solid #222",
    borderRadius: "6px",
    "&:hover": {
      backgroundColor: "#111",
    },
  },
  ".formkit-alert-success": {
    color: "#009958",
    fontSize: 4,
    fontWeight: "bold",
    my: 4,
  },
  title: {
    fontSize: [4, 6],
    fontWeight: 900,
    mb: 0,
  },
  description: {
    fontSize: [2, 3],
    my: 2,
    opacity: 0.8,
  },
  Unsubscribe: {
    fontSize: 1,
    opacity: 0.8,
  },
}

export default convertkitStyles
