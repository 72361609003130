/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import TagsConstructor from "../components/tags"
import {
  FaTwitter,
  FaFacebookSquare,
  FaInstagram,
  FaGithub,
} from "react-icons/fa"
import "../assets/scss/footernav.scss"

const footerNavStyles = {
  footerIcons: {
    display: "flex",
    justifyContent: "left",
    gridGap: [4, 3],
    mt: 4,
    fontSize: 4,
    "a:hover": {
      color: "#000",
    },
  },
  footerSpan: {
    display: "none",
  },
  nav: {
    display: "grid",
    gridTemplateColumns: [
      "repeat(1, 1fr)",
      "repeat(2, 1fr)",
      "repeat(3, 1fr)",
      "repeat(4, 1fr)",
    ],
    gridGap: 4,
  },
  heading: {
    fontSize: 2,
    textTransform: "uppercase",
    color: "rgb(121,156,45)",
    fontWeight: "bold",
  },
  navBox: {
    padding: 4,
    background: "#ffffff",
    borderTop: "3px solid rgb(183, 185, 162)",
    borderRadius: "12px",
    h3: {
      fontSize: 1,
      textTransform: "uppercase",
      color: "#709520",
      fontWeight: "bold",
    },
  },
}

const MenuItems = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/jamstack/",
    title: "Jamstack Templates",
  },
  {
    path: "/website-templates/",
    title: "Website Templates",
  },
  {
    path: "/mobile-templates/",
    title: "Mobile Templates",
  },
  {
    path: "/newsletter/",
    title: "Newsletter",
  },
  {
    path: "/blog/",
    title: "Blog",
  },
  {
    path: "/contact/",
    title: "Contact",
  },
  {
    path: "/submit/",
    title: "Submit template",
  },
]

const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

class Navigation extends React.Component {
  render() {
    const listMenuItems = MenuItems.map(menuItem => (
      <ListLink key={menuItem.title.toString()} to={menuItem.path}>
        {menuItem.title}
      </ListLink>
    ))
    return (
      <nav className="footer-nav" sx={footerNavStyles.nav}>
        <div sx={footerNavStyles.navBox}>
          <h3 sx={footerNavStyles.heading}>Stackrole</h3>
          <ul>{listMenuItems}</ul>
          <div>
            <div sx={footerNavStyles.footerIcons}>
              <OutboundLink
                href="https://facebook.com/stackrolecom"
                target="_blank"
                rel="noreferrer"
              >
                <span sx={footerNavStyles.footerSpan}>Facebook</span>
                <FaFacebookSquare />
              </OutboundLink>
              <OutboundLink
                href="https://instagram.com/stackrole"
                target="_blank"
                rel="noreferrer"
              >
                <span sx={footerNavStyles.footerSpan}>Instagram</span>
                <FaInstagram />
              </OutboundLink>
              <OutboundLink
                href="https://twitter.com/stackrole"
                target="_blank"
                rel="noreferrer"
              >
                <span sx={footerNavStyles.footerSpan}>Twitter</span>
                <FaTwitter />
              </OutboundLink>
              <OutboundLink
                href="https://github.com/stackrole"
                target="_blank"
                rel="noreferrer"
              >
                <span sx={footerNavStyles.footerSpan}>Github</span>
                <FaGithub />
              </OutboundLink>
            </div>
          </div>
        </div>
        <TagsConstructor {...footerNavStyles.navBox} />
      </nav>
    )
  }
}

export default Navigation
