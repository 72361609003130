/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const tagsStyles = {
  heading: {
    fontSize: "12px",
    textTransform: "uppercase",
    color: "var(--accent-color)",
  },
}

const query = graphql`
  query {
    cmses: allStrapiHeadlessCms(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          prefix
          slug
          products {
            id
            Status
          }
        }
      }
    }
    ssgs: allStrapiSsg(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          prefix
          slug
          products {
            id
          }
        }
      }
    }
    stylings: allStrapiStyling(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          prefix
          slug
          products {
            id
          }
        }
      }
    }
  }
`

const TagsConstructor = tagContainer => {
  const tags = useStaticQuery(query)

  const { cmses, stylings, ssgs } = tags

  const cmsList = cmses.edges
    .filter(edge => edge.node.products.length > 0)
    .filter(product => product.node.Status !== "draft")
    .map((edge, index) => (
      <li key={"cms" + index}>
        <Link to={edge.node.prefix + edge.node.slug + "/"}>{edge.node.title}</Link>
      </li>
    ))

  const ssgList = ssgs.edges
    .filter(edge => edge.node.products.length > 0)
    .filter(product => product.node.Status !== "draft")
    .map((edge, index) => (
      <li key={"ssg" + index}>
        <Link to={edge.node.prefix + edge.node.slug + "/"}>{edge.node.title}</Link>
      </li>
    ))

  const stylingsList = stylings.edges
    .filter(edge => edge.node.products.length > 0)
    .filter(product => product.node.Status !== "draft")
    .map((edge, index) => (
      <li key={"style" + index}>
        <Link to={edge.node.prefix + edge.node.slug + "/"}>{edge.node.title}</Link>
      </li>
    ))

  return (
    <React.Fragment>
      <div sx={tagContainer}>
        <h3 sx={tagsStyles.heading}>Static Site Generator</h3>
        <ul>{ssgList}</ul>
      </div>
      <div sx={tagContainer}>
        <h3 sx={tagsStyles.heading}>Headless CMS</h3>
        <ul>{cmsList}</ul>
      </div>
      <div sx={tagContainer}>
        <h3 sx={tagsStyles.heading}>Styling</h3>
        <ul>{stylingsList}</ul>
      </div>
    </React.Fragment>
  )
}

export default TagsConstructor
