/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"

import LogoSVG from "../../static/assets/logo.svg"
import LogoWheel from "../../static/assets/stackrole-wheel@2x.png"
import "../assets/scss/logo.scss"

const Logo = props => (
  <div className={"site-logo " + props.className}>
    <Link to={props.url}>
      <img className="logo" src={LogoSVG} alt={props.title + " - Logo"} />
      <img
        className="spinner"
        src={LogoWheel}
        alt={props.title + " - Wheel"}
        sx={{
          bottom: ["2px", "3px"],
        }}
      />
      <span className="hidden">{props.title}</span>
    </Link>
  </div>
)

export default Logo
