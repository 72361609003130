import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"

import "../assets/scss/style.scss"
import Footer from "./footer"

const query = graphql`
  query LayoutQuery {
    site {
      siteMetadata {
        siteTitle: title
        siteUrl
        twitterUsername
      }
    }
  }
`

const Layout = ({ children, className }) => {
  const { site } = useStaticQuery(query)
  const { siteTitle } = site.siteMetadata
  const meta = site.siteMetadata

  return (
    <>
      <Header title={siteTitle} />
      <main className={className}>{children}</main>
      <Footer
        siteName={meta.siteTitle}
        twitterUsername={meta.twitterUsername}
        siteURL={meta.siteUrl}
      />
    </>
  )
}

export default Layout
