/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import FooterNav from "../components/footer-nav"
import Convertkit from "../components/convertkit"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import "../assets/scss/footer.scss"

const footerStyles = {
  siteFooter: {
    m: "0 auto",
    px: [3, 4, 4, 5],
    maxWidth: "1440px",
  },
  copyright: {
    padding: "20px",
    mx: "auto",
    mt: 4,
    fontSize: 1,
    bg: "#fff",
    display: ["block", "block", "flex"],
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "12px",
    color: "footerTextcolor",
    p: {
      lineHeight: "160%",
    },
    a: {
      color: "footerLinkcolor",
      "&:hover": {
        color: "#709520",
      },
    },
  },
}

const Footer = ({ siteName, siteURL }) => {
  return (
    <footer className="site-footer">
      <section sx={footerStyles.siteFooter}>
        <Convertkit />
      </section>

      <div sx={footerStyles.siteFooter}>
        <FooterNav />
      </div>

      <div sx={footerStyles.siteFooter}>
        <div sx={footerStyles.copyright}>
          <p>
            &copy; {new Date().getFullYear()}{" "}
            <Link to={siteURL}>{siteName}</Link> &bull; Proudly built on{" "}
            <OutboundLink href="https://jamstack.org/">Jamstack</OutboundLink>{" "}
            with{" "}
            <OutboundLink href="https://www.gatsbyjs.com/">Gatsby</OutboundLink>{" "}
            and <OutboundLink href="https://strapi.io/">Strapi</OutboundLink>{" "}
            &bull; Hosting by{" "}
            <OutboundLink href="https://netlify.com">Netlify</OutboundLink>
          </p>
          <div>
            <OutboundLink
              href="https://www.producthunt.com/posts/stackrole-jamstack-marketplace?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-stackrole-jamstack-marketplace"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=289353&theme=dark"
                alt="Stackrole — Jamstack Marketplace - Curated marketplace for Jamstack templates | Product Hunt"
                style={{ width: "250px", height: "54px", display: "block" }}
                width="250"
                height="54"
              />
            </OutboundLink>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
